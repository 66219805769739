import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The last time I put on lipstick, I think I was about 5 years old. It was an old tube I had found in my mother’s cabinet, that I had reached out to grab and open. I didn’t think much of it at first, to me it was just a thing to play with. I didn’t mind the way the red smeared against my lips, and that it felt messy on my face. I didn’t mind that it made my brown eyes look dull in comparison or that the contours of my face stood out even less. I didn’t mind it at all, but my mother did. When she walked in to see me with the smears on my face, she slapped me on the wrist and took away the tube. She looked at me in anger and told me, “Boys shouldn’t wear makeup,”.`}</p>
    <p>{`The last time I grew out my hair, I think it was my ninth birthday. Up until then, I had always had it cut short. And it’s not that I didn’t like it short, it felt better long. I let it grow for a long time, watching as the strands grew from touching my neck to my back. Since it was long, I could wear it in a ponytail when I wanted to. I liked how it bounced when I moved, how it shifted with the wind and how soft it was to touch. Then my father told me it was getting too long, that I should have it cut or it’d “make me look like a woman”. So I had it cut, having gone full-circle.`}</p>
    <p>{`The last time I felt normal was when I was 13. My friends talked about girls and new hobbies and things they learned about themselves. When we talked, I remained quiet. They talked about everything like it was obvious, like everyone in the group felt the same. But I really didn’t. I didn’t want to look at girls and talk about the ways my body had changed over the years. I wanted to tell them honestly, that I wanted to talk about movies and dresses, ribbons and bows. But at that point, I figured if I said something like that I’d only get a punch in my gut. So I remained silent.`}</p>
    <p>{`The last time I felt loved was when I turned 15. It was just before I had come out to my parents about my feelings. It had been a rainy day, which I should’ve taken as a cue for misfortune. I had told them I had something I wished to tell. And so I told them about how I felt. How I liked the feeling of lipstick on my face more than the lips of a girl. How I liked my hair touching the small of my back rather than shortened behind my ear. How I liked talking about movies and dresses and ribbons and bows more than talking about girls and bodies.`}</p>
    <p>{`How I didn’t like my body. How when I looked at it, all I felt was a disconnection from my mind. How my body was stoic and leen and not at all the way I wanted it to be, curvy and petite.`}</p>
    <p>{`That was the last time I felt loved, as my parents hit me for my words. And I was told that I was a disgrace as their son. And then they told me to get out. I had no other choice so I did.`}</p>
    <p>{`The last time I felt like myself was just a short time after. In my own room, the darkness could feel engulfing on starless nights. I felt alone in that space, unbothered as I breathed in and out and wondered what had I done wrong. That’s what I wondered as I breathed in and out at the time too. I don’t think breathing had ever felt so heavy as with a rope around my neck, as I gasped and rasped my voice out.`}</p>
    <p>{`The last time I was here, I also wasn’t. It might have been my body which stood there in the midst of all others, but it was never me, the person. I might have been a facade to most, my voice silenced and my thoughts unknown. But maybe in the next life I’ll be blessed. Maybe I can have a body that I love and embrace, and people around me that know it. That my existence won’t be a taboo in society, and I can be myself without being afraid of what others will do and say.`}</p>
    <p>{`Until that time, I’ll be waiting for the last time I have to live my life as someone I am not. For the last time I’ll be silenced for what is unknown to most. For the last time I feel left alone.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      